import './styles.index.scss';

import axios from 'axios';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  AccountInfo,
  Configuration,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import {createTheme, ThemeProvider} from "@mui/material";
import * as serviceWorker from './serviceWorker';

import {Navigation} from "./features/navigation/navigation.component";
import {Home} from "./features/home/home.component";
import {Dataflow} from "./features/dataflow/dataflow.component";
import {PrivateRoutes} from "./features/navigation/privateRoutes";
import Upload from "./features/upload/upload.component";
import { ProductInfo } from './features/product/product.types';
import { ThreatModelInfo } from './features/threatmodel/threatmodel.types';
import { getCurrentProduct, getCurrentThreatModel, removeItemFromSession, setCurrentSessionProduct, setCurrentSessionThreatModel } from './features/sessionStorage/sessionStorage';

const queryClient = new QueryClient();

const theme = createTheme({
  components: {
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 16,
                  fontWeight: "lighter",
              },
              h1: {
                  fontSize: 20,
                  fontWeight: "lighter",
              }
          }
      },
      MuiTextField: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  '& input': {
                      fontFamily: "Noto Sans",
                      fontSize: 16,
                      fontWeight: "lighter",
                  },
                  '& label.Mui-focused': {
                      // Label when selected
                      color: '#555555',
                  },
                  '& .MuiInput-underline:after': {
                      borderBottomColor: '#cccccc',
                  },
                  '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                          // Not selected and not hovering over
                          borderColor: '#cccccc',
                      },
                      '&:hover fieldset': {
                          // Hovering over
                          borderColor: '#cccccc',
                      },
                      '&.Mui-focused fieldset': {
                          // Selected
                          borderWidth: "1px",
                          borderColor: '#cccccc',
                      },
                  },
              },
          }
      },
      MuiAlert: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 16,
                  fontWeight: "lighter",
                  color: "#ffffff",
                  '& .MuiAlert-icon': {
                      fontSize: 24,
                      color: "#ffffff",
                  }
              },
              standardSuccess: {
                  backgroundColor: "#a4d596",
              },
              standardInfo: {
                  backgroundColor: "#ffaa7a",
              },
              standardError: {
                  backgroundColor: "#e66969",
              }
          }
      },
      MuiButton: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 13,
                  fontWeight: "normal",
                  color: "#000000",
                  '&:hover': {
                      backgroundColor: "#e6696900",
                  }
              }
          }
      },
      MuiSwitch: {
          styleOverrides: {
              switchBase: {
                  backgroundColor: "#00000000",
              },
              track: {
                  "&.Mui-disabled": {
                      color: "#e886a9"
                  },
                  "&.Mui-checked": {
                      color: "#95cc97"
                  },
                  "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#4CAF50",
                  }
              },
              colorPrimary: {
                  "&.Mui-checked": {
                      color: "#000000",
                  },
                  "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#4CAF50",
                  },
              },
          }
      },
      MuiInputLabel: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 16,
                  fontWeight: "lighter",
              }
          }
      },
      MuiContainer: {
          styleOverrides: {
              root: {

              }
          }
      },
      MuiPagination: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 16,
                  fontWeight: "lighter",
              },
          }
      },
      MuiSelect: {
          styleOverrides: {
              outlined: {
                  fontFamily: "Noto Sans",
                  fontSize: 13,
                  fontWeight: "lighter",
                  borderBottom: "none",
                  padding: "3px 0px 2px 7px",
              }
          }
      },
      MuiMenuItem: {
          styleOverrides: {
              root: {
                  fontFamily: "Noto Sans",
                  fontSize: 13,
                  fontWeight: "lighter",
              }
          }
      },
  },
  breakpoints: {
      values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1750,  // Edit this to change the <Container maxWidth="xl"> width
      },
  }
})

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

const AuthError = ({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element => (
  <AuthStatusScreen>
    <div className="auth__error-title">An Error Occurred</div>

    {error?.errorMessage ? (
      <div className="auth__error-message">{error.errorMessage}</div>
    ) : null}
  </AuthStatusScreen>
);

const AuthLoading = (): JSX.Element => (
  <AuthStatusScreen>
    <div>Loading</div>
  </AuthStatusScreen>
);

export type IAuthContext = {
  accessToken: string;
  account: AccountInfo;
  config: Record<string, unknown>;
};

export const AuthContext = React.createContext<IAuthContext>({
  accessToken: '',
  config: {},
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  },
});

export type SessionContextProps = {
  sessionProduct: ProductInfo | undefined;
  sessionModel: ThreatModelInfo | undefined;
  setSessionProduct: (product: ProductInfo) => void;
  setSessionModel: (product: ThreatModelInfo) => void;
  setModel: (model: ThreatModelInfo) => void;
};

export const SessionContext = React.createContext<SessionContextProps>({
  sessionProduct: undefined,
  sessionModel: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSessionProduct: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSessionModel: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModel: () => {},
});

axios
  .get('/config.json')
  .then((res) => {
    const config = res.data;

    const authConfiguration: Configuration = {
      auth: {
        redirectUri: '/',
        clientId: config.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    const authInstance = new PublicClientApplication(authConfiguration);

    const AuthProvider = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      const auth = useMsal();
      const [account] = auth.instance.getAllAccounts();
      const [rumInitiated, setRum] = React.useState(false);
      const [accessToken, setAccessToken] = React.useState('');

      React.useEffect(() => {
        if (!rumInitiated) {
          const DATADOG_SITE = 'datadoghq.eu';
          const DATADOG_SERVICE = 'react-template';
          const DATADOG_PROXY_HOST = 'datadog-proxy.app.wartsila.com';
          const DATADOG_APP_ID = 'app-id';
          const DATADOG_CLIENT_TOKEN = 'client-token';

          datadogRum.init({
            sampleRate: 100,
            site: DATADOG_SITE,
            trackInteractions: true,
            service: DATADOG_SERVICE,
            applicationId: DATADOG_APP_ID,
            proxyHost: DATADOG_PROXY_HOST,
            clientToken: DATADOG_CLIENT_TOKEN,
            env: config.REACT_APP_ENVIRONMENT,
            version: config.REACT_APP_VERSION,
            allowedTracingOrigins: [config.REACT_APP_API_BASE_URL],
          });

          datadogRum.setUser({
            name: account.name,
            email: account.username,
          });

          setRum(true);
        }
      }, [rumInitiated, account]);

      React.useEffect(() => {
        if (!accessToken) {
          auth.instance
            .acquireTokenSilent({
              account,
              scopes: [`${config.REACT_APP_API_CLIENT_ID}/.default`],
            })
            .then((accountInformation) =>
              setAccessToken(accountInformation.accessToken)
            );
        }
      }, [accessToken]);

      return (
        <AuthContext.Provider value={{ accessToken, account, config }}>
          {children}
        </AuthContext.Provider>
      );
    };

    const SessionDataProvider = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      const [sessionProduct, setProduct ] = React.useState<ProductInfo | undefined>(getCurrentProduct());
      const [sessionModel, setModel] = React.useState<ThreatModelInfo | undefined>(getCurrentThreatModel());

      const setSessionModel = (model: ThreatModelInfo): void => {
        setModel(model);
        if (model !== undefined) {
          setCurrentSessionThreatModel(model);
        }
        else{
          removeItemFromSession("ThreatModel");
        }
        };

        const setSessionProduct = (product: ProductInfo): void => {
          setProduct(product);
          setModel(undefined);
          if (product !== undefined) {
            setCurrentSessionProduct(product);
          }
          else{
            removeItemFromSession("Product");
          }
          };

      const value = {
        sessionProduct,
        setProduct,
        setSessionProduct,
        sessionModel,
        setModel,
        setSessionModel
      };

      return (
        <SessionContext.Provider value={value}>
          {children}
        </SessionContext.Provider>
      );
    };

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={authInstance}>
            <MsalAuthenticationTemplate
              errorComponent={AuthError}
              loadingComponent={AuthLoading}
              interactionType={InteractionType.Redirect}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <SessionDataProvider>
                    <Router>
                      <Navigation/>
                      <Routes>
                          <Route path="" element={<Home/>}/>
                          <Route element={<PrivateRoutes requiredRoles={["theremin_ng_admin"]}/>}>
                            <Route path="/dataflows" element={<Dataflow/>}/>
                            <Route path="/upload" element={<Upload/>}/>
                          </Route>
                      </Routes>
                    </Router>
                  </SessionDataProvider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </ThemeProvider>
      </React.StrictMode>
    );

    serviceWorker.unregister();
  })
  .catch();
