import axios from 'axios';

import { useQuery, UseQueryResult } from 'react-query';
import { useAuth } from '../auth/auth.hooks';
import { ThreatModelInfo } from './threatmodel.types';


export const getThreatModelInfo= (productId: number): UseQueryResult<ThreatModelInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery(
      ['threatmodel', productId],
      () =>
        axios
          .get<ThreatModelInfo[]>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels`,
            { params: {
              product_id: productId,
              }, headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      {
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
      }
    );
};
