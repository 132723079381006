import { Box, Container } from "@mui/material";
import { getThreatModelInfo } from './threatmodel.hooks';
import { CustomDropDown } from "../dropdown/dropdown.component";



interface ModelProps {
    productId: number;
}

export function ModelSelection({productId}: ModelProps): JSX.Element {


    const modelInfo = getThreatModelInfo(productId);
    return (
        <Box>
            <CustomDropDown
                productInfo={undefined}
                modelInfo={modelInfo.data}
                isSuccess={modelInfo.isSuccess}
                isError={modelInfo.isError}
                isLoading={modelInfo.isLoading}/>
        </Box>
    );
}

export function ThreatModel({productId}: ModelProps): JSX.Element {
    return (
        <Box>
            <Container maxWidth="xl">
                <ModelSelection productId={productId}/>
            </Container>
        </Box>
    );
}
