import { useState } from "react";
import { Breadcrumbs, Container } from "@mui/material";
import { ProductSelection } from "../product/product.component";
import { ThreatModel } from "../threatmodel/threatmodel.component";
import { ProductInfo } from '../product/product.types';
import { getCurrentProduct } from "../sessionStorage/sessionStorage";


export function Breadcrumb(): JSX.Element {
  const [selectedProduct, setSelection] = useState<ProductInfo>();
  const currentProduct = getCurrentProduct();
  if (selectedProduct === undefined) {
    if (currentProduct !== undefined && currentProduct !== null && selectedProduct !== currentProduct) {
      setSelection(currentProduct);
    }
}

  return (
      <Container className="breadcrumbs" maxWidth="xl">
          <Breadcrumbs aria-label="breadcrumb"
          sx={{
            "& ol": {
              justifyContent: "left",
              margin: "auto"
            }
          }}>
            <ProductSelection onProductChanged={(product) =>
                                {
                                  setSelection(
                                    product
                                  )
                                }
                            }/>
            {(selectedProduct !== undefined && selectedProduct !== null && selectedProduct.mycyber_id !== 0) && (
              <ThreatModel productId={selectedProduct.mycyber_id}/>
            )}
        </Breadcrumbs>
      </Container>
  );
}
