import { Alert, Box, Container, SelectChangeEvent } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridFilterModel} from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Banner } from "../banner/banner.component";
import { useDataflowInfo, useDataflowInfoCount} from './dataflow.hooks';
import { CustomDataGrid } from "../dataGrid/datagrid.component";
import { searchCacheGet, searchCacheSet} from "../dataGrid/searchCache";
import { useSessionContext } from "../sessionStorage/session.hooks";

interface gridProps {
    searchCacheKey: string;
}

export function DataflowGrid({searchCacheKey}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const {
        sessionModel
      } = useSessionContext();
    let modelId = 0;
    if (sessionModel) {
        modelId = sessionModel.id;
    }
    const filter = useRef<any[] | undefined>(undefined);
    const rowCount = useDataflowInfoCount(modelId, search);
    const dataflowInfo = useDataflowInfo(modelId, paginationModel.pageSize, paginationModel.page, search);
    // const navigate = useNavigate();


    const columnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        /* {field: "id", headerName: "ID", minWidth: 50, editable: false}, */
        {field: "src_zone_name", headerName: "Src Zone", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-src-header"},
        {field: "src_device_name", headerName: "Src Device", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-src-header"},
        {field: "src_function_name", headerName: "Src Function", minWidth: 200, flex: 1, editable: false, headerClassName: "grid-src-header"},
        {field: "src_port", headerName: "Src Port", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-src-header"},
        {field: "type", headerName: "Type", minWidth: 150, flex: 1, editable: false, headerClassName: "grid-src-header"},
        {field: "link", headerName: "Link", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-link-header"},
        {field: "protocol", headerName: "Protocol", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-proto-header"},
        {field: "dst_zone_name", headerName: "Dst Zone", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-dst-header"},
        {field: "dst_device_name", headerName: "Dst Device", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-dst-header"},
        {field: "dst_function_name", headerName: "Dst Function", minWidth: 200, flex: 1, editable: false, headerClassName: "grid-dst-header"},
        {field: "dst_port", headerName: "Dst Port", minWidth: 100, flex: 1, editable: false, headerClassName: "grid-dst-header"},
        {field: "active", headerName: "Active", minWidth: 200, flex: 1, editable: false, headerClassName: "grid-act-header"},
        {field: "description", headerName: "Description", minWidth: 200, flex: 1, editable: false, headerClassName: "grid-desc-header"},
        /* {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, renderCell: (params: GridRenderCellParams) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.id;
                    navigate({pathname: "/viewDataflowData", search: `?object_id=${objectId}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        } */
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
    })

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    return (
        <Box>
            {dataflowInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!dataflowInfo.isError && (
                <Box>
                    <Box style={{height: 670, width: "100%"}}
                        sx={{
                        '& .grid-src-header': {
                            borderBottom: '5px solid #053B57 !important'
                        },
                        '& .grid-link-header': {
                            borderBottom: '5px solid #FFAA7A !important',
                            marginLeft: '3px'
                        },
                        '& .grid-proto-header': {
                            borderBottom: '5px solid #FFAA7A !important',
                            marginRight: '3px'
                        },
                        '& .grid-dst-header': {
                            borderBottom: '5px solid #B8D9AF !important'
                        },
                        '& .grid-act-header': {
                            borderBottom: '5px solid #FFDFCF !important',
                            marginLeft: '3px'
                        },
                        '& .grid-desc-header': {
                            borderBottom: '5px solid #FFDFCF !important'
                        },
                    }}>
                        <CustomDataGrid
                            columnConfig={columnConfig}
                            itemInfo={dataflowInfo.data}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={rowCount.data !== undefined ? rowCount.data : 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => setSearch(filter.current)}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            isLoading={dataflowInfo.isLoading}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function Dataflow(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Data Flows" secondLine="Search" alignToLeft />
            <Container maxWidth={false}>
                <DataflowGrid searchCacheKey="dataflows"/>
            </Container>
        </Box>
    );
}
