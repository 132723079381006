import { useMemo, useState } from "react";
import {Box, Container, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import { ThreatModelInfo } from "../threatmodel/threatmodel.types";
import  AddModelDialog  from "../threatmodel/add-model/addmodel.component";
import { ProductInfo } from "../product/product.types";
import { useSessionContext } from "../sessionStorage/session.hooks";

const MenuProps = {
    autoFocus: false,
    sx: {
        '& .MuiMenuItem-root': {
            fontSize: 20,
            fontWeight: "regular",
        },
    }
};

interface DropdownProps {
    productInfo: ProductInfo[] | undefined;
    modelInfo: ThreatModelInfo[] | undefined;
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    onProductChanged?: (product: ProductInfo) => void;
}

interface customDropDownListPropsProduct {
    data: ProductInfo[] | undefined;
    success: boolean;
    loading: boolean;
    error: boolean;
    onProductChanged: (product: ProductInfo) => void;
}

interface customDropDownListPropsModel {
    data: ThreatModelInfo[] | undefined;
    success: boolean;
    loading: boolean;
    error: boolean;
}


const containsText = (text: string, searchText: string): boolean =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;


function CustomDropDownListThreatModel({data, success, loading, error}: customDropDownListPropsModel): JSX.Element | null {
    if(loading) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>Loading models...</Typography>
        );
    }

    if(error) return null;


    if( success && data !== undefined && data !== null) {
        const defaultModel: ThreatModelInfo = {
            product_mycyber_id: 0,
            name: "Select a model",
            id: -1
          };
        const {
            sessionModel,
            setSessionModel,
          } = useSessionContext();
        let selectedModel = defaultModel;
        if (sessionModel) {
            selectedModel = sessionModel;
        }
        const [searchText, setSearchText] = useState("");
        const displayedModels = useMemo(
        () => data.filter((option) => containsText(option.name, searchText)),
        [data, searchText]
        );

        return (
        <Box>
            <FormControl fullWidth>
            <InputLabel id="search-select-label">Model</InputLabel>
            <Select
                MenuProps={MenuProps}
                labelId="search-select-label"
                id="search-select"
                value={selectedModel.id}
                label="Models"
                onChange={(event: SelectChangeEvent<number>) => {
                    const id = event.target.value as number
                    const model = data.find(item => item.id === id);
                    if (model !== undefined) {
                        setSessionModel(model);
                    }

                    }}
                onClose={() => setSearchText("")}
                renderValue={() => selectedModel.name}
            >
                {/* TextField is put into ListSubheader so that it doesn't
                    act as a selectable item in the menu
                    i.e. we can click the TextField without triggering any selection. */}
                <ListSubheader>
                <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start"/>
                    )
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                    }
                    }}
                />
                </ListSubheader>
                {displayedModels.map((model, i) => (
                <MenuItem key={model.id} value={model.id} divider={i < displayedModels.length - 1}>
                    {model.name}
                </MenuItem>
                ))}
                <ListSubheader>
                    <AddModelDialog/>
                </ListSubheader>
            </Select>
            </FormControl>
        </Box>
        );

    }


    return null;
}

function CustomDropDownListProduct({data, success, loading, error, onProductChanged}: customDropDownListPropsProduct): JSX.Element | null {
    if(loading) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>Loading products...</Typography>
        );
    }

    if(success && data !== undefined && data.length === 0) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>No results found...</Typography>
        );
    }

    if(error) return null;


    if(data !== undefined && data !== null && data.length > 0) {
        const defaultProduct: ProductInfo = {
            mycyber_id: -1,
            name: "Select a product"
          };
        const {
            sessionProduct,
            setSessionProduct,
          } = useSessionContext();
        let selectedProduct = defaultProduct;
        if (sessionProduct) {
            selectedProduct = sessionProduct;
        }
        const [searchText, setSearchText] = useState("");
        const displayedProducts = useMemo(
        () => data.filter((option) => containsText(option.name, searchText)),
        [searchText]
        );

        return (
        <Box >
            <FormControl fullWidth>
            <InputLabel id="search-select-label">Product</InputLabel>
            <Select
                MenuProps={MenuProps}
                labelId="search-select-label"
                id="search-select"
                value={selectedProduct.mycyber_id}
                label="Products"
                onChange={(event: SelectChangeEvent<number>) => {
                    const id = event.target.value as number
                    const product = data.find(item => item.mycyber_id === id);
                    if (product !== undefined) {
                        setSessionProduct(product);
                        onProductChanged(product);
                    }

                    }}
                onClose={() => setSearchText("")}
                renderValue={() => selectedProduct.name}
            >
                {/* TextField is put into ListSubheader so that it doesn't
                    act as a selectable item in the menu
                    i.e. we can click the TextField without triggering any selection. */}
                <ListSubheader>
                <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start"/>
                    )
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                    }
                    }}
                />
                </ListSubheader>
                {displayedProducts.map((p, i) => (
                <MenuItem key={p.mycyber_id} value={p.mycyber_id} divider={i < displayedProducts.length - 1}>
                    {p.name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Box>
        );

    }


    return null;
}

export function CustomDropDown({
    productInfo,
    modelInfo,
    isSuccess,
    isLoading,
    isError,
    onProductChanged
}: DropdownProps): JSX.Element {

    let searchItemsProducts;
    let searchItemsModels;

    if(modelInfo !== undefined) {
        searchItemsModels = (modelInfo !== null && isSuccess) ? modelInfo : [];
    }
    else {
        searchItemsProducts = (productInfo !== null && isSuccess) ? productInfo : [];
    }

    return (
        <Box>
            <Container maxWidth={false}>
                <Box className="dropDownList">
                        {onProductChanged &&
                            <CustomDropDownListProduct data={searchItemsProducts} success={isSuccess} loading={isLoading} error={isError} onProductChanged={onProductChanged}/>
                        }
                        {!onProductChanged &&
                            <CustomDropDownListThreatModel data={searchItemsModels} success={isSuccess} loading={isLoading} error={isError}/>}
                    </Box>
            </Container>
        </Box>
    );
}
