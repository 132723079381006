import React, {MouseEventHandler} from "react";
import axios, {AxiosResponse} from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { useAuth } from '../../auth/auth.hooks';
import { getCurrentProduct} from "../../sessionStorage/sessionStorage"
import { ProductInfo } from '../../product/product.types';


export const withHooksHOC = (Component: React.ElementType) => function withHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const sessionProduct = getCurrentProduct();
    return <Component accessToken={accessToken} config={config} currentProduct={sessionProduct} />;
};

interface IHooksHOCProps {
    accessToken: string;
    config: Record<string, unknown>;
    currentProduct: ProductInfo;
}

interface ModelDialogState {
    dialogOpen: boolean;
    validName: boolean;
    nameHelperText: string;
}

interface CreateButtonProps {
    validName: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>
}

interface ModelNameFieldProps {
    validName: boolean;
    nameHelperText: string;
    onValueChanged: (value: string) => void;
}

export function CreateButton({validName, onClick}: CreateButtonProps): JSX.Element | null {
    return(
        <Button
            sx={{ margin: 1 }}
            style={{
                color: "#ffffff",
                backgroundColor: validName ? "#0f334a" : "#99b7c4" }}
            variant="text"
            type="submit"
            form="model-form"
            disableRipple
            onClick={onClick}
            disabled={!validName}>
            Create
        </Button>
    );
}

export function ModelNameField({validName, nameHelperText, onValueChanged}: ModelNameFieldProps): JSX.Element | null {
    const [inputValue, setInputValue] = React.useState('')

    React.useEffect(() => {
        const timer = setTimeout(() => {
            onValueChanged(inputValue)
        }, 500)

        return () => clearTimeout(timer)
    }, [inputValue])

    return (
        <TextField
            autoFocus
            margin="dense"
            id="model_name"
            label="Model Name"
            fullWidth
            variant="filled"
            required
            error={!validName}
            helperText={nameHelperText}
            FormHelperTextProps={{ sx: { color: validName ? 'green' : 'red' } }}
            onChange={e => {
                setInputValue(e.target.value)
                }}
            InputProps={{
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {e.stopPropagation();},
                }}
            InputLabelProps={{
            sx: {
                left: "-10px",
            }
            }}
            />
    );
}

class AddModelDialog extends React.Component<IHooksHOCProps, ModelDialogState> {

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            dialogOpen: false,
            validName: false,
            nameHelperText: "Name is required",
        }
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkNameAvailibility = this.checkNameAvailibility.bind(this);
    }

    handleClickOpen = (): void  => {
        this.setState({
            dialogOpen: true
        })
    };

    handleClose = (): void  => {
        this.setState({
            dialogOpen: false,
            validName: false,
            nameHelperText: "Name is required",
        })
    };

    handleSubmit = (event: any): void => {
        if(event.target.form && event.target.form.checkValidity()){ // use can also use e.target.reportValidity
            const {accessToken} = this.props;
            const {config} = this.props;
            const {currentProduct} = this.props;
            const data = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${accessToken}`
                },
                params: {
                    product_id: currentProduct.mycyber_id,
                    name: event.target.form.model_name.value
                }
            }
            const callback = (response: AxiosResponse): void => {
                if (response.status === 200) {
                    console.log("Success");
                } else {
                    console.log("Fail");
                }
            }
            axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels`, {}, data)
                .then((response) => {
                    callback(response);
                }).catch(error => {
                    if(!error.response) {
                        this.setState({
                            dialogOpen: true
                        })
                    }
                    else {
                        this.setState({
                            dialogOpen: false,
                            validName: false,
                            nameHelperText: "Name is required",
                        })
                    }
            })
        }
    }

    checkNameAvailibility = (newName: string): void  => {
        if (newName === "") {
            this.setState({
                validName: false,
                nameHelperText: "Name is required"
            })
        }
        const {accessToken} = this.props;
        const {config} = this.props;
        const {currentProduct} = this.props;
        const data = {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            params: {
                product_id: currentProduct.mycyber_id,
                name: newName
            }
        }
        const callback = (response: AxiosResponse): void => {
            if (response.status === 200) {
                if (response.data) {
                    this.setState({
                        validName: true,
                        nameHelperText: "Name is available"
                    })
                }
                else {
                    this.setState({
                        validName: false,
                        nameHelperText: "Name is already in use"
                    })
                }

            } else {
                this.setState({
                    validName: false,
                    nameHelperText: "Name is already in use"
                })
            }
        }
        axios.get(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels/name_available`, data)
            .then((response) => {
                callback(response);
            }).catch(error => {
                if(!error.response) {
                    this.setState({
                        validName: false,
                        nameHelperText: "Undefined error while checking name"
                    })
                }
                else {
                    this.setState({
                        validName: false,
                        nameHelperText: "Error while checking name"
                    })
                }
        })
    }

   // Render the page
    render(): JSX.Element {
    const {dialogOpen} = this.state;
    const {validName} = this.state;
    const {nameHelperText} = this.state;
    const {currentProduct} = this.props;

        return (
            <Box style={{marginTop:5, marginBottom:5}}>
                <Button style={{
                        color: "#ffffff",
                        backgroundColor: "#0f334a"}}
                        onClick={this.handleClickOpen}>
                    Add Model
                </Button>
                <Dialog open={dialogOpen} onClose={this.handleClose}>
                    <DialogTitle>Add Threatmodel</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{fontWeight: "bold", mb: "10px"}}>
                            {currentProduct.name}
                        </DialogContentText>
                        <form id="model-form" onSubmit={e => this.handleSubmit(e)}>
                            <ModelNameField
                                validName={validName}
                                nameHelperText={nameHelperText}
                                onValueChanged={(value) =>
                                {
                                    if (value === "") {
                                        this.setState({
                                            validName: false,
                                            nameHelperText: "Name is required"
                                        })
                                    }
                                    else {
                                        this.checkNameAvailibility(value)
                                    }
                                }
                            } />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ color: "#ffffff", backgroundColor: "#0f334a"}}
                            onClick={this.handleClose}>Cancel</Button>
                        <CreateButton validName={validName}
                            onClick={this.handleSubmit}/>
                    </DialogActions>
                </Dialog>
            </Box>
        );
}

}

export default withHooksHOC(AddModelDialog);
